import styled from 'styled-components';

export const LoaderContenedor = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  background: linear-gradient(53deg, rgba(2, 0, 36, 1) 0%, rgba(184, 138, 0, 1) 0%, rgba(251, 221, 1, 1) 100%, rgba(255, 255, 255, 1) 100%);
`;
