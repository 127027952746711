import { PageProps } from 'gatsby';
import QueueAnim from 'rc-queue-anim';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../shared/components/Loader/Loader';
import '../styles/styles.scss';
import LandingPage from './landing/Landing';

const IndexPage: FC<PageProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const timeout = 3000;

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), timeout);
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <>
      <Helmet>
        <title lang="es-MX">Aguila Azteca Nutrition - La Marca de Otro Nivel</title>
        <html lang="es-MX" />
        <meta charSet="utf-8" />
        <meta name="title" content="Aguila Azteca Nutrition - La Marca de Otro Nivel" />
        <meta
          name="description"
          content="Proteínas, pre entrenadores, aminoácidos, colágeno, glutamina y muchos productos más te ofrece ÁGUILA AZTECA NUTRITION"
        />
        <meta name="keywords" content="suplementos, proteína, aminoácidos, colágeno, glutamina, vitamina, pre entrenadores" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Spanish" />
        <meta name="author" content="Developers House" />
      </Helmet>
      <QueueAnim type={['right', 'left']} leaveReverse>
        {loading ? <Loader key="loader" timeout={timeout} /> : <LandingPage key="landing" {...props} />}
      </QueueAnim>
    </>
  );
};

export default IndexPage;
