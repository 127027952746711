import React, { FC } from 'react';
import Loader from 'react-loader-spinner';
import { LoaderContenedor } from './Loader.styled';

interface Props {
  timeout: number;
}

const Loading: FC<Props> = ({ timeout }) => {
  return (
    <>
      <LoaderContenedor>
        <p key="title">Cargando...</p>
        <Loader key="loader" type="TailSpin" color="#00BFFF" height={100} width={100} timeout={timeout} />
      </LoaderContenedor>
    </>
  );
};

export default Loading;
